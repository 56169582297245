import * as Actions from './actions';
import * as Constants from '../constants';
import { combineReducers } from 'redux';

const init = {
    currentlySelectedSection: Constants.Home
};

function global(state = init, action) {
    switch(action.type) {
        case Actions.UPDATE_SELECTED_SECTION:
            return {...state, currentlySelectedSection: action.section};
        default: 
            return state;
    }
};

const workflow = combineReducers({global});
export default workflow;