import React, { useState, useCallback } from 'react';

import { Grid } from 'semantic-ui-react';
import PhotoGallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

export default function Gallery(props) {
    const photos = [
        {
            src: "/img/Zion003.JPG",
            width: 4,
            height: 3
        },
        {
            src: "/img/Hero006.JPG",
            width: 4,
            height: 3
        },
        {
            src: "/img/Zion005.JPG",
            width: 4,
            height: 3
        },
        {
            src: "/img/Hero004.JPG",
            width: 4,
            height: 3
        },
        {
            src: "/img/RC01.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Trendy001a.jpg",
            width: 4,
            height: 5
        },
        {
            src: "/img/SantaInDetroit.jpg",
            width: 4,
            height: 5
        },
        {
            src: "/img/NW009.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/NW007.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/NW002.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/NW001.jpg",
            width: 4,
            height: 5
        },
        {
            src: "/img/Mojo3.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Mojo2.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Claus01.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Claus02.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Claus03.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Santa005.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/2.jpg",
            width: 4,
            height: 4
        },
        {
            src: "/img/3.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/0094.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Blarneystone1c.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/checking-list.jpg",
            width: 5,
            height: 3
        },
        {
            src: "/img/GR4.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/GR6.jpg",
            width: 4,
            height: 5
        },
        {
            src: "/img/IMG_3222c.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/kids1.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/kids2.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/kids4.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/MrsClaus1b.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/MrsClaus4a.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/MrsClaus5b.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Remax004.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Santa1.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Santa6.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Wedding1a.jpg",
            width: 4,
            height: 2
        },
        {
            src: "/img/Wedding2b.jpg",
            width: 4,
            height: 4
        },
        {
            src: "/img/Wedding3a.jpg",
            width: 4,
            height: 3
        },
        {
            src: "/img/Wedding4a.jpg",
            width: 4,
            height: 3
        }
    ];

    const init = {
        index: 0,
        open: false
    };

    const [current, setCurrent] = useState(init);

    const openLightBox = useCallback((event, { photo, index }) => {
        setCurrent({ index, open: true });
        props.onModalOpen(true);
    }, []);

    const closeLightBox = () => {
        setCurrent({ index: 0, open: false });
        props.onModalOpen(false);
    };

    return (
        <div style={{ minHeight: "95vh", background: "linear-gradient(164deg, rgba(7,64,15,1) 0%, rgba(47,101,55,1) 50%, rgba(7,64,15,1) 100%)" }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <p style={{ color: "white", fontFamily: "Courgette, cursive", fontSize: "36px" }}>Gallery - Click to Enlarge!</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column only="computer" width={1} textAlign="center"></Grid.Column>
                    <Grid.Column only="computer" width={14} textAlign="center">
                        <PhotoGallery photos={photos} onClick={openLightBox} />
                    </Grid.Column>
                    <Grid.Column only="computer" width={1} textAlign="center"></Grid.Column>
                    <Grid.Column only="tablet mobile" width={16} textAlign="center">
                        <PhotoGallery photos={photos} onClick={openLightBox} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <ModalGateway>
                {current.open ? (
                    <Modal onClose={closeLightBox} >
                        <Carousel
                            currentIndex={current.index}
                            views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    )
}