export const Home = "home";
export const About = "about";
export const Contact = "contact";
export const Gallery = "gallery";
export const Testimonials = "testimonials";

export const MobileBreakpoint = 1024;

export const WhiteFont = {
    fontFamily: "'Roboto Slab', serif",
    color: "white",
    fontSize: "1.5rem",
    lineHeight: "2.2rem"
}