import React from 'react';
import { Image, Responsive } from 'semantic-ui-react';
import { MobileBreakpoint } from '../constants';

import Banner from '../img/banner.jpg';
import BannerMobile from '../img/banner-mobile.jpg';

export default function Home () {
    return (
        <React.Fragment>
            <Responsive minWidth={MobileBreakpoint}>
                <Image src={Banner} style={{minHeight: "95vh"}} fluid centered/>
            </Responsive>
            <Responsive maxWidth={MobileBreakpoint - 1}>
                <Image src={BannerMobile} style={{minHeight: "95vh"}} fluid centered/>
            </Responsive>
        </React.Fragment>        
    );
}