import React, { useEffect, useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import { Element, animateScroll as scroll, scroller } from 'react-scroll'

import * as Constants from './constants';
import { useSelector } from 'react-redux';

import Header from './Components/Header';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import Gallery from './Components/Gallery';
import Testimonials from './Components/Testimonials';

export default function App() {
  const currentlySelected = useSelector(state => state.global.currentlySelectedSection);

  useEffect(() => {    
      scroller.scrollTo(currentlySelected, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
  }, [currentlySelected]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const headerPadding = {paddingTop: "80px", background: "repeating-linear-gradient(45deg, #fff, #ff0000 10px, #ff0000 10px, #fff 20px)"};

  return (
    <React.Fragment>
      <Element name={Constants.Home}>
        {isModalOpen ? null : <Header />}
        <Home />
      </Element>
      <Element name={Constants.About} style={isModalOpen ? undefined : headerPadding}><About /></Element>
      <Element name={Constants.Contact} style={isModalOpen ? undefined : headerPadding}><Contact /></Element>
      <Element name={Constants.Gallery} style={isModalOpen ? undefined : headerPadding}><Gallery onModalOpen={(open) => {setIsModalOpen(open)}}/></Element>
      <Element name={Constants.Testimonials} style={isModalOpen ? undefined : headerPadding}><Testimonials /></Element>
    </React.Fragment>
  );
}
