import React from 'react';

import { Grid, Image, Icon } from 'semantic-ui-react';
import Sitting from '../img/GR110319.jpg';
import { WhiteFont } from '../constants';

export default function Contact() {
    return (
        <div style={{ minHeight: "95vh", background: "linear-gradient(164deg, rgba(167,0,24,1) 0%, rgba(77,1,11,1) 50%, rgba(167,0,24,1) 100%)" }}>
            <Grid>
                <Grid.Row style={{ height: "100%" }}>
                    <Grid.Column computer={10} tablet={16} mobile={16} >
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <p style={{ color: "white", fontFamily: "Courgette, cursive", fontSize: "36px" }}>Contact</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                {/* Desktop */}
                                <Grid.Column width={1}></Grid.Column>
                                <Grid.Column width={14}>
                                    <div style={{ marginBottom: ".25em", textAlign: "center" }}>
                                        <p style={WhiteFont}>Please book early for the months of November and December.<br />My calendar fills quickly!</p>
                                        <div style={{ alignItems: "center", verticalAlign: "middle" }}>
                                            <div>
                                                <Icon name="phone volume" color="green" size="big" style={{ marginBottom: ".25em" }} />
                                                <a style={WhiteFont} href="tel:7348122662">(734) 812-2662</a>
                                            </div>
                                            <p></p>
                                            <div style={{ marginBottom: "2em" }}>
                                                <Icon name="mail" color="green" size="big" />
                                                <a style={WhiteFont} href="mailto:dmbargers@hotmail.com">dmbargers@hotmail.com</a>
                                            </div>
                                        </div>
                                        <p style={WhiteFont}>Background check available on request.</p>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={1}></Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div style={{ display: "flex", justifyContent: "center"}}>
                                        <div style={{ width: "650px", height: "400px" }}>
                                            <video width="100%" controls={true} poster="/img/newsthumb.jpg" playsInline>
                                                <source src="/img/SantaInTheNews.MP4" type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <p style={{ color: "white", fontStyle: "italic", textAlign: "center" }}>Santa in the news!</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column only="computer" width={6}>
                        <Image src={Sitting} style={{ height: "92.5vh" }} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}