import React, { useState } from 'react';
import { Menu, Responsive, Sidebar, Image } from 'semantic-ui-react';
import * as Constants from '../constants';
import * as Actions from '../Stores/actions';

import { useDispatch } from 'react-redux';
import Burger from '../img/burger.png';

export default function Header() {
    const dispatch = useDispatch();

    const setItem = section => event => {
        dispatch({ type: Actions.UPDATE_SELECTED_SECTION, section: section });
        handleSideBar(false);
    };

    const [showSidebar, handleSideBar] = useState(false);

    const headerStyle = {
        color: "white",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        paddingRight: "6rem",
        fontFamily: "Courgette, cursive",
        fontSize: "45px"
    };

    const mobileHeaderStyle = {
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        fontFamily: "Courgette, cursive",
        fontSize: "45px",
        height: "60px",
        position: "fixed",
        zIndex: "99"
    };

    const clearHeight = "60px";

    const fixedSpacer = <div style={{ height: "55px" }}></div>;

    const menuItems = (
        <React.Fragment>
            <Menu.Item
                onClick={setItem(Constants.Home)}
            >Home</Menu.Item>
            <Menu.Item
                onClick={setItem(Constants.About)}
            >About</Menu.Item>
            <Menu.Item
                onClick={setItem(Constants.Contact)}
            >Contact</Menu.Item>
            <Menu.Item
                onClick={setItem(Constants.Gallery)}
            >Gallery</Menu.Item>
            <Menu.Item
                onClick={setItem(Constants.Testimonials)}
            >Testimonials</Menu.Item>            
        </React.Fragment>
    )

    const candyCaneStyle = {
        background: "repeating-linear-gradient(45deg, #fff, #ff0000 10px, #ff0000 10px, #fff 20px)",
        position: "fixed",
        top: clearHeight,
        height: "10px",
        width: "100%",
        zIndex: "99"
    }

    return (
        <React.Fragment>
            {/* Begin Desktop */}
            <Responsive minWidth={Constants.MobileBreakpoint}>
                <Menu color="red" inverted className="deep-red" fluid style={{ position: "fixed", zIndex: "99", height: clearHeight }}>
                    {menuItems}
                    <div style={headerStyle}>Santa Doug</div>
                </Menu>
                {fixedSpacer}
            </Responsive>

            {/* Begin Mobile */}
            <Responsive maxWidth={Constants.MobileBreakpoint - 1}>
                <div className="deep-red" style={mobileHeaderStyle}>
                    <Image size="mini"
                        src={Burger}
                        onClick={() => { handleSideBar(true) }}
                        style={{ position: "absolute", left: "30px", cursor: "pointer", zIndex: "99" }}
                        hidden={showSidebar}
                    />
                    Santa Doug
                </div>
                {fixedSpacer}
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={() => { handleSideBar(false) }}
                    vertical
                    visible={showSidebar}
                    width='thin'
                    className="deep-red"
                    style={{ zIndex: "100" }}
                >
                    {menuItems}
                </Sidebar>
            </Responsive>
            <div style={candyCaneStyle}></div>
        </React.Fragment>
    )
}
