import React from 'react';
import { Grid, Image } from 'semantic-ui-react';

import { WhiteFont } from '../constants';
import Gloves from '../img/Santa001.JPG';

export default function About() {
    return (
        <div style={{ minHeight: "95vh", background: "linear-gradient(164deg, rgba(7,36,7,1) 0%, rgba(23,75,25,1) 50%, rgba(7,36,7,1) 100%)" }}>
            <Grid>
                <Grid.Row style={{ height: "100%" }}>
                    <Grid.Column only="computer" width={6}>
                        <Image src={Gloves} style={{ height: "92.5vh" }} />
                    </Grid.Column>
                    <Grid.Column computer={10} tablet={16} mobile={16} >
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <p style={{ color: "white", fontFamily: "Courgette, cursive", fontSize: "36px" }}>About</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                {/* Desktop */}
                                <Grid.Column width={1}></Grid.Column>
                                <Grid.Column width={14}>
                                    <p style={WhiteFont}>I am a real bearded Santa, and, as a representative of the jolly old elf himself, I take my appearance and presentation seriously. I am a retired middle school English grammar teacher and great with children of all ages. It was my love of children that drew me into teaching and so inspired me to become a Santa Claus. I am warm and friendly to everyone I meet while portraying Santa and throughout the year. As a professional Santa, my goal is to brighten your Christmas and Holiday Events, and bring good cheer to your Home, Private Parties, Office Parties, and Corporate and Community Events. I will star in your photo shoots, and even attend your Christmas in July events. I'm all in for meet and greets, sing along, and I love a good reading of "The Night Before Christmas."</p>
                                    <p style={WhiteFont}>I am a member in good standing of the <a href="http://michigansantas.ning.com/" style={{ color: "#21ba45" }} target="_blank" rel="noopener noreferrer">Michigan Association of Professional Santas</a> and <a href="https://www.ibrbsantas.org/" style={{ color: "#21ba45" }} target="_blank" rel="noopener noreferrer">International Brotherhood of Real Bearded Santas</a>.</p>
                                </Grid.Column>
                                <Grid.Column width={1}></Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div style={{ width: "650px", height: "400px" }}>
                                            <video width="100%" height="100%" poster="/img/emuthumb.jpg" controls={true} playsInline>
                                                <source src="/img/emusanta.mov" type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}