import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

export default function Testimonials() {
    const reviews = [
        {
            review: "Santa Doug did a fanstastic job doing what we asked. He went above by staying a little over to ensure everyone had their chance for photos with Santa! Thank you!",
            name: "Review by ARIANNA H. FROM BIRMINGHAM, MI ON 12/6/2019"
        },
        {
            review: "Santa Doug was absolutely INCREDIBLE at our wedding. He was quick to communicate with before hand, friendly, on time, and ready for pictures with any guests that came his way. He was the talk of our reception and NEEDS to be your Santa for any Christmas event that you have in mind. Thank you from the bottom of our hearts, Santa Doug!",
            name: "Review by Patrick G. FROM DETROIT, MI ON 12/22/2018"
        },
        {
            review: "Best in the business!! He is the real deal, you wont find a better Santa!! Highly recommended",
            name: "Review by Danielle D. FROM BRIGHTON, MI ON 12/12/2018"
        },
        {
            review: "Santa Doug was the warmest, most realistic Santa I have seen. From the voice to the outfit to the mannerisms . My hope is to have him return for our winter event every year",
            name: "Review by Natalie V. FROM WARREN, MI ON 12/7/2018"
        },
        {
            review: "Santa Doug went above and beyond! He was more than accommodating to our needs and made for a FANTASTIC Santa. We cannot wait to have him back again!",
            name: "Review by Robert W. FROM STERLING HEIGHTS, MI ON 12/1/2018"
        },
        {
            review: "Santa Doug was PERFECT for our event! We loved having him and everyone just loved him!",
            name: "Review by Stephanie B. FROM WESTLAND, MI ON 11/25/2018"
        },
        {
            review: "Santa Doug was the most realistic Santa I have ever seen. He was a joy to work with as well! One child came out and said in amazement 'Santa is real!!' We will definitely hire him for our next event! Kim",
            name: "Review by Kim F. FROM TOLEDO, OH ON 11/23/2018"
        },
        {
            review: "From the moment Santa Doug arrived, it was such a delight! He entered our home and was immediately greeted by both children and adults. We sang Christmas songs and had so much fun together. He was extremely interactive and never broke character. He had little gifts for the kids and took time to read them a short story. The kids were absolutely hooked. He gave every one individual attention and made sure that everyone was happy. We were so amazed with Santa Doug and we will definitely have him back for years to come!",
            name: "Review by Lidya G. FROM NOVI, MI ON 12/24/2017"
        },
        {
            review: "Amazing !!!!!! I would give Santa Doug 10 if they would let me. We had him at our Military Christmas Party. He got there early and stayed late so all the kids and families had time to sit with him and take pictures. I would recommend him to anyone and we will be using him for our Christmas party next year.",
            name: "Review by Terence C. FROM SAINT CLAIR SHORES, MI ON 12/21/2017"
        },
        {
            review: "Santa Doug was a hit at our First Annual Family Christmas party! From request to the end of the party he was attentive to our needs and communicated to make sure this was the best event it could be.. The kids loved him, the parents were impressed with costume, cheerful disposition and the quality time spend with each child. Santa Doug really made our party and I hope we get to work together again next year!",
            name: "Review by Theda C. FROM CLINTON TOWNSHIP, MI ON 12/16/2017"
        },
        {
            review: "Santa Doug is AMAZING. My children can’t stop talking about him. He brought so many special treats and was so patient taking pictures with all the children and families. I will be rebooking for years to come and would highly recommend him to anyone! He played the role perfectly and was very professional and kind. Thank you Santa Doug, my children have never been so excited about Santa!",
            name: "Review by Ollia M. FROM WHITE LAKE, MI ON 12/10/2017"
        },
        {
            review: "Santa Doug was outstanding! The kids were so very happy and he was such a pleasure to have at our home. I can't express how thankful I am to Santa Doug for making Cousins Christmas a hit! I will definitely recommend Santa Doug to others.",
            name: "Review by Megan K. FROM LIVONIA, MI ON 12/9/2017"
        },
        {
            review: "Santa Doug was FANTASTIC!! We had over 100 children and all the families were raving about him!",
            name: "Review by Kathy J. FROM WALLED LAKE, MI ON 12/7/2017"
        }
    ];

    const candyCaneStyle = {
        background: "repeating-linear-gradient(45deg, #fff, #ff0000 10px, #ff0000 10px, #fff 20px)",        
        height: "10px",
        width: "100%",
        zIndex: "99"
    };

    return (
        <div style={{ minHeight: "95vh", background: "linear-gradient(164deg, rgba(167,0,24,1) 0%, rgba(77,1,11,1) 50%, rgba(167,0,24,1) 100%)" }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <p style={{ color: "white", fontFamily: "Courgette, cursive", fontSize: "36px" }}>Testimonials</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row only="computer">
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div style={candyCaneStyle}></div>
                                </Grid.Column>
                                {
                                    reviews.map(x =>
                                        <Grid.Column width={16} key={x.name}>
                                            <Segment raised color="red">
                                                <p style={{textAlign: "center", fontSize: "16px"}}>{x.review}</p>
                                                <p style={{textAlign: "center", fontSize: "16px"}}><strong>{x.name}</strong></p>
                                                <p></p>
                                            </Segment>
                                            <div style={candyCaneStyle}></div>
                                        </Grid.Column>
                                    )
                                }
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row only="tablet mobile">
                <Grid.Column width={16}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div style={candyCaneStyle}></div>
                                </Grid.Column>
                                {
                                    reviews.map(x =>
                                        <Grid.Column width={16} key={x.name}>
                                            <Segment raised color="red">
                                                <p style={{textAlign: "center", fontSize: "16px"}}>{x.review}</p>
                                                <p style={{textAlign: "center", fontSize: "16px"}}><strong>{x.name}</strong></p>
                                                <p></p>
                                            </Segment>
                                            <div style={candyCaneStyle}></div>
                                        </Grid.Column>
                                    )
                                }
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}